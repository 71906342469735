import React, { useState, useEffect } from "react";
import "./App.css";

function App() {
  const [data, setData] = useState(null);

  useEffect(() => {
    // Fetching data from the given API
    fetch("https://processors.fjsapi.com/status")
      .then((response) => response.json())
      .then((result) => {
        setData(result);
      })
      .catch((error) => console.error("Error:", error));
  }, []);

  if (!data) return <div>Loading...</div>;

  const timestamp = data.comp_monitor_timestamp;

  return (
    <div>
      <h1>Processor Health Report</h1>
      <p>
        {timestamp}
        <br />
        (time data compiled)
      </p>
      <p>
        This report show my processors that are currently online and offline. At
        the top of the hour each online processor sends it's hostname to an RDIS
        database. At three minutes past, the list of computers that have checked
        in is compared to a list of all computers and the report data is
        compiled. <a href="https://processors.fjsapi.com/status">Raw Data:</a>{" "}
        and <a href="https://processors.fjsapi.com/docs/">Swager docs</a>{" "}
      </p>
      <div className="list-container">
        <div className="online-processors">
          <h2>Online Processors</h2>
          <p>(hostnames)</p>
          <ol className="list">
            {data.last_online_processors.map((processor) => (
              <li key={processor}>{processor}</li>
            ))}
          </ol>
        </div>
        <div className="offline-processors">
          <h2>Offline Processors</h2>
          <p>(hostnames)</p>
          <ol className="list">
            {data.offline_processors.map((processor) => (
              <li key={processor}>{processor}</li>
            ))}
          </ol>
        </div>
      </div>
    </div>
  );
}

export default App;
